import Link from "next/link";
import { useState } from "react";

/* eslint-disable @next/next/no-img-element */

import ProfileBubble from "@/components/sections/ProfileBubble";
import ExpandoList from "@/components/ui/ExpandoList";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { classNames, smartTruncate } from "@/lib/utils";
import { entityStore } from "@/stores/entityStore";
import { AttributeType, EntityType, PipelineRunStatus, ProfilePageSection } from "@/types";

import {
  AcademicCapIcon,
  BeakerIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  HandRaisedIcon,
} from "@heroicons/react/20/solid";
import { AnchorHTMLAttributes } from "react";

import ConditionalLink from "@/components/ui/ConditionalLink";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import {
  BaseRelationshipData,
  PersonCompanyRelationship,
  RelationshipDataEducatedAt,
  RelationshipDataInvestedIn,
  RelationshipDataVolunteeredAt,
  RelationshipDataWorkedAt,
  RelationshipWithEntity,
} from "@/models/relationship/relationshipTypes";
import { findAttribute } from "@/stores/utils";
import { useStore } from "@nanostores/react";
import { Entity, Relationship } from "@prisma/client";
import moment from "moment";
import Markdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { twJoin } from "tailwind-merge";

enum CompanyType {
  Stealth = "stealth",
  SelfEmployed = "self-employed",
  CareerBreak = "career-break",
  Company = "company",
  Education = "education",
  Investments = "investments",
  Volunteering = "volunteering",
  OtherExperience = "other-experience",
}

type CompanyConfig = {
  name: string;
  icon: JSX.Element | undefined;
  type: CompanyType;
  description?: string;
  link?: string;
};

const ExperienceLabels: { [key in string]: string } = {
  [ProfilePageSection.WorkHistory]: "Work History",
  [ProfilePageSection.Investments]: "Investments",
  [ProfilePageSection.OtherExperience]: "Other Experience",
  [ProfilePageSection.Volunteering]: "Volunteering",
  [ProfilePageSection.Education]: "Education",
};

const isGridView = (section: string) => section == (ProfilePageSection.Investments as string);

// powered by relationships
export default withErrorBoundary(function WorkEducation({ status }: { status: PipelineRunStatus }) {
  const relationshipSections = useStore(entityStore.relationshipSections);

  const workHistory = relationshipSections[ProfilePageSection.WorkHistory];
  const hasLegacyData =
    (workHistory?.length == 0 &&
      (findAttribute(entityStore.attributes.get(), AttributeType.LinkedinProfile)?.value.experience
        .length || 0) > 0) ||
    workHistory?.find((r) => !(r.data as RelationshipDataWorkedAt)?.title);

  if (hasLegacyData) {
    return (
      <div className="text-center">
        {status == PipelineRunStatus.IN_PROGRESS ? "Loading..." : "No recent relationship data yet"}
      </div>
    );
  }

  return (
    <>
      {Object.keys(ExperienceLabels).map((section) => {
        const experiences = relationshipSections[section as ProfilePageSection] || [];
        if (!experiences.length) return null;
        return (
          <ProfileBubble
            title={ExperienceLabels[section] + " v3"}
            key={section}
            section={section as ProfilePageSection}
            className="flex flex-col"
          >
            <ExpandoList
              items={experiences}
              limit={!isGridView(section) ? 5 : 6}
              seeMoreClassName="pt-4 text-center"
              className={twJoin(
                "list-inside text-gray-700 gap-4",
                !isGridView(section) ? "flex flex-col" : "flex flex-col sm:grid sm:grid-cols-2",
              )}
              divideExpander
              renderItem={(workExperience, index) => (
                <WorkRow
                  key={index}
                  allExperiences={experiences}
                  workExperience={workExperience}
                  index={index}
                  section={section}
                />
              )}
            />
          </ProfileBubble>
        );
      })}
    </>
  );
});

function WorkRow({
  allExperiences,
  workExperience,
  index,
  section,
}: {
  allExperiences: RelationshipWithEntity[];
  workExperience: RelationshipWithEntity;
  index: number;
  section: string;
}) {
  if (section == (ProfilePageSection.Investments as string)) {
    const date = workExperience.startedDate && formatDate(workExperience.startedDate);
    let title = (workExperience.data as RelationshipDataWorkedAt)?.title;
    if (title?.toLocaleLowerCase() == "investor") title = undefined;
    const description = smartTruncate(
      (workExperience.data as BaseRelationshipData)?.description || "",
      100,
    );
    const subtitle = [date, title, description].filter(Boolean).join(" · ");

    return (
      <div className="group flex flex-col flex-1 gap-2 static">
        <CompanyRow
          key={index}
          workExperience={workExperience}
          section={section}
          customDescription={subtitle}
        />
      </div>
    );
  }

  const showCompany =
    !workExperience.toId || allExperiences[index - 1]?.toId != workExperience.toId;
  const showDivider = showCompany && index != 0;

  const subsequentPositionIdx = allExperiences
    .slice(index)
    .findIndex((experience) => !!experience.toId && experience.toId != workExperience.toId);
  const lastPositionAtCompany =
    (showCompany ?
      allExperiences[
        subsequentPositionIdx == -1 ? allExperiences.length - 1 : index + subsequentPositionIdx - 1
      ]
    : undefined) || workExperience;

  const dates = calculateDateString(lastPositionAtCompany, workExperience);
  const positionDates =
    !showCompany || lastPositionAtCompany != workExperience ?
      calculateDateString(workExperience, workExperience)
    : undefined;

  return (
    <div className="group flex flex-col flex-1 gap-2 static">
      {showDivider && <hr className="mb-2" />}
      {showCompany && (
        <CompanyRow key={index} workExperience={workExperience} section={section} dates={dates} />
      )}
      <DetailsRow workExperience={workExperience} dates={positionDates} />
    </div>
  );
}

const formatDate = (date: string | null) => {
  if (!date || date == "Present") {
    return "Present";
  }
  if (date.length == 4) {
    return date;
  }
  const dateObj = moment(date);
  return dateObj.isValid() ? dateObj.format("MMM YYYY") : date || "";
};

const dateStringToMoment = (date: string | null) => {
  if (date == "Present" || !date) {
    return moment();
  }
  return moment(date);
};

function calculateDateString(
  startPosition: RelationshipWithEntity,
  endPosition: RelationshipWithEntity,
) {
  let startDate = startPosition.startedDate;
  let endDate = endPosition.endedDate;

  if (!startDate && !endDate) return undefined;

  if (startDate && endDate && dateStringToMoment(startDate).isAfter(dateStringToMoment(endDate))) {
    [startDate, endDate] = [endDate, startDate];
  }

  if (startDate == endDate) return formatDate(startDate);

  const duration =
    startDate ?
      dateStringToMoment(endDate).from(dateStringToMoment(startDate)).replace("in ", "")
    : undefined;

  const dates =
    startDate ? `${formatDate(startDate)} - ${formatDate(endDate)} · ${duration}` : undefined;
  return dates;
}

function CompanyRow({
  workExperience,
  dates,
  customDescription,
}: {
  workExperience: RelationshipWithEntity;
  section: string;
  dates?: string;
  customDescription?: string | null;
}) {
  const companyEntity = workExperience.to;
  const companyConfig = getCompanyConfig({
    workExperience,
    companyType: parseEmploymentTypeFromCompany(workExperience),
    companyEntity,
  });

  const description =
    customDescription == undefined ? companyConfig.description : customDescription;

  return (
    <>
      <div className={classNames("flex items-center")}>
        <ConditionalLink
          href={companyEntity?.slug || undefined}
          data-tooltip-id="tooltip"
          data-tooltip-content={companyEntity ? `See ${companyEntity.name}'s profile` : undefined}
        >
          <EntityIconWithPlaceholder
            entity={{
              id: companyEntity?.id || workExperience.id,
              name: companyEntity?.name || workExperience.toName || workExperience.type,
              imageUrl:
                companyEntity?.imageUrl && !companyEntity?.imageUrl?.includes("static.licdn.com") ?
                  companyEntity?.imageUrl
                : workExperience.toImageUrl,
              fallbackImageUrl: workExperience.toImageUrl,
              url: companyEntity?.url,
              type: EntityType.Company,
            }}
            className="h-10 w-10 mt-[2px] mr-4 border border-gray-100"
            customIcon={companyConfig.icon}
          />
        </ConditionalLink>
        <div>
          <ConditionalLink
            href={companyEntity?.slug || undefined}
            className="text-base font-semibold underline mr-2 cursor-pointer"
          >
            {companyConfig.name.replace("\\'", "'")}
          </ConditionalLink>

          <span className="text-xs text-gray-400">{dates}</span>
        </div>
      </div>
      {description && (
        <div className=" sm:-mt-2">
          <div className="text-sm sm:ml-14">{smartTruncate(description, 300)}</div>
          <div className="h-[8px]" />
        </div>
      )}
    </>
  );
}

function DetailsRow({
  workExperience,
  dates,
}: {
  workExperience: Relationship;
  dates: string | undefined;
}) {
  const data = workExperience.data;
  const title =
    (data as RelationshipDataEducatedAt)?.degree ||
    (data as RelationshipDataWorkedAt)?.title ||
    (data as RelationshipDataVolunteeredAt)?.role;
  const location = (data as RelationshipDataWorkedAt)?.location;
  const type = (data as RelationshipDataWorkedAt)?.type;

  const subtitle = [dates, type, location].filter(Boolean).join(" · ");
  if (!title && !subtitle) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <div className="flex items-center">
          <span className="hidden sm:inline font-bold text-xl text-gray-300 ml-4 mr-8">•</span>
          <div className="">
            <span className="font-medium">{title}</span>
            {subtitle && <span className="ml-2 text-xs text-gray-400">{subtitle}</span>}
          </div>
        </div>
        <div className="sm:ml-14">
          <DescriptionRow relationship={workExperience} />
        </div>
      </div>
    </div>
  );
}

const EXPANDO_THRESHOLD = 300;

function DescriptionRow({ relationship }: { relationship: Relationship }) {
  const [expanded, setExpanded] = useState(false);

  const data = relationship.data as BaseRelationshipData;
  if (!data.description) return null;

  const needsExpando = !expanded && data.description.length > EXPANDO_THRESHOLD;

  return (
    <>
      <Markdown
        className="text-xs whitespace-pre-wrap inline"
        remarkPlugins={[remarkBreaks]}
        components={{
          a(props: AnchorHTMLAttributes<HTMLAnchorElement>) {
            const { title, ...rest } = props;
            return (
              <a
                {...rest}
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded((e) => !e);
                }}
                className="text-gray-600 font-bold cursor-pointer"
              />
            );
          },
        }}
      >
        {needsExpando ?
          smartTruncate(data.description, EXPANDO_THRESHOLD - 30) + " [Read more](#more)"
        : data.description}
      </Markdown>
    </>
  );
}

export const parseEmploymentTypeFromCompany = (relationship: Relationship): CompanyType => {
  const lowercaseCompany = relationship.toName?.toLowerCase().trim() ?? ""; // trim seems to be necessary for accuracy

  if (relationship.type == PersonCompanyRelationship.EducatedAt) {
    return CompanyType.Education;
  }

  if (relationship.type == PersonCompanyRelationship.InvestedIn) {
    return CompanyType.Investments;
  }

  if (relationship.type == PersonCompanyRelationship.VolunteeredAt) {
    return CompanyType.Volunteering;
  }

  const companyTypeFromKeywords = maybeGetCompanyTypeFromKeywords(
    lowercaseCompany,
    !!relationship.toId,
  );
  if (companyTypeFromKeywords) {
    return companyTypeFromKeywords;
  }

  if (relationship.type == PersonCompanyRelationship.OtherExperience) {
    return CompanyType.OtherExperience;
  }
  return CompanyType.Company;
};

export function maybeGetCompanyTypeFromKeywords(
  lowercaseCompany: string,
  urlPresent: boolean,
): CompanyType | undefined {
  const stealthKeywords = ["stealth", "stealth startup", "stealth company", "stealth mode"]; // This is a bit of a hack, we should have enum somewhere in the BE for this
  const selfEmployedKeywords = [
    "self-employed",
    "self employed",
    "freelance",
    "freelancer",
    "freelance (self employed)",
  ];
  const careerBreakKeywords = ["career break"];

  if (stealthKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase())) {
    return CompanyType.Stealth;
  }
  if (selfEmployedKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase())) {
    return CompanyType.SelfEmployed;
  }
  if (
    careerBreakKeywords.some((keyword) => lowercaseCompany === keyword.toLowerCase()) &&
    !urlPresent
  ) {
    return CompanyType.CareerBreak;
  }
}

export const getCompanyConfig = ({
  workExperience,
  companyType,
  companyEntity,
}: {
  workExperience: Relationship;
  companyType: CompanyType;
  companyEntity: Entity | undefined | null;
}): CompanyConfig => {
  let name = companyEntity?.name || workExperience.toName;
  const subtitle = (workExperience.data as RelationshipDataWorkedAt)?.title || "";
  let icon = undefined;

  switch (companyType) {
    case CompanyType.Stealth:
      if (!name) name = "Stealth Company";
      icon = <BeakerIcon className="h-4 w-4" />;
      break;
    case CompanyType.SelfEmployed:
      if (!name) name = "Self-Employed";
      icon = <BriefcaseIcon className="h-4 w-4" />;
      break;
    case CompanyType.Company:
      if (!name) name = "Company";
      break;
    case CompanyType.CareerBreak:
      if (!name) name = "Career Break";
      icon = <CalendarIcon className="h-4 w-4" />;
      break;
    case CompanyType.Education:
      if (!name) name = "Education";
      icon = <AcademicCapIcon className="h-4 w-4" />;
      break;
    case CompanyType.Investments:
      if (!name) name = "Investments";
      icon = <CurrencyDollarIcon className="h-4 w-4" />;
      break;
    case CompanyType.Volunteering:
      if (!name) name = "Volunteering";
      icon = <HandRaisedIcon className="h-4 w-4" />;
      break;
    case CompanyType.OtherExperience:
      if (!name) name = "Other Experience";
      icon = <CalendarDaysIcon className="h-4 w-4" />;
      break;
    default:
      const _exhaustiveCheck: never = companyType;
      throw new Error("Unhandled company type");
  }

  let description = companyEntity?.description ?? undefined;
  if (description == "(no description)") description = undefined;
  return { name, icon, type: companyType, description };
};
