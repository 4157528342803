import { ReactNode, useState } from "react";
import { twMerge } from "tailwind-merge";

interface ExpandoListProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => ReactNode;
  limit?: number;
  className?: string;
  seeMoreClassName?: string;
  divideExpander?: boolean;
}

function ExpandoList<T>({
  className,
  items,
  renderItem,
  limit = 5,
  seeMoreClassName,
  divideExpander = false,
}: ExpandoListProps<T>) {
  const [isExpanded, setIsExpanded] = useState(false);

  const showExpando = items.length > limit + 1;
  const visibleItems = !showExpando || isExpanded ? items : items.slice(0, limit);

  return (
    <>
      {className ?
        <div className={className}>{visibleItems.map(renderItem)}</div>
      : visibleItems.map(renderItem)}
      {showExpando && (
        <>
          {divideExpander && <hr className="mt-4" />}
          <button
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
            className={twMerge(
              "text-blue-600 hover:text-blue-800 transition duration-150 ease-in-out cursor-pointer ",
              seeMoreClassName,
            )}
          >
            {isExpanded ? "See less" : `See ${items.length - limit} more items`}
          </button>
        </>
      )}
    </>
  );
}

export default ExpandoList;
