import { RelationshipWithEntity } from "@/models/relationship/relationshipTypes";
import { ProfilePageSection } from "@/types";
import moment from "moment/moment";

export enum CompanyType {
  Stealth = "stealth",
  SelfEmployed = "self-employed",
  CareerBreak = "career-break",
  Company = "company",
  Education = "education",
  Investments = "investments",
  Volunteering = "volunteering",
  OtherExperience = "other-experience",
}

export type CompanyConfig = {
  name: string;
  icon: JSX.Element | undefined;
  type: CompanyType;
  description?: string;
  link?: string;
};

export const ExperienceLabels: { [key in string]: string } = {
  [ProfilePageSection.WorkHistory]: "Work History",
  [ProfilePageSection.Investments]: "Investments",
  [ProfilePageSection.OtherExperience]: "Other Experience",
  [ProfilePageSection.Volunteering]: "Volunteering",
  [ProfilePageSection.Education]: "Education",
};

export type GroupedExperiences = RelationshipWithEntity[];

export const formatDate = (date: string | null) => {
  if (!date || date == "Present") {
    return "Present";
  }
  if (date.length == 4) {
    return date;
  }
  const dateObj = moment(date);
  return dateObj.isValid() ? dateObj.format("MMM YYYY") : date || "";
};

const dateStringToMoment = (date: string | null) => {
  if (date == "Present" || !date) {
    return moment();
  }
  return moment(date);
};

export function calculateDateString(
  startPosition: RelationshipWithEntity,
  endPosition: RelationshipWithEntity,
) {
  let startDate = startPosition.startedDate;
  let endDate = endPosition.endedDate;

  if (!startDate && !endDate) return undefined;

  if (startDate && endDate && dateStringToMoment(startDate).isAfter(dateStringToMoment(endDate))) {
    [startDate, endDate] = [endDate, startDate];
  }

  if (startDate == endDate) return formatDate(startDate);

  const duration =
    startDate ?
      dateStringToMoment(endDate).from(dateStringToMoment(startDate)).replace("in ", "")
    : undefined;

  const dates =
    startDate ? `${formatDate(startDate)} - ${formatDate(endDate)} · ${duration}` : undefined;
  return dates;
}
